import React from "react";
import { Box, Button, Card, Tab, Tabs, Typography, Pagination, useMediaQuery } from "@mui/material";

import theme from "../utils/theme";
import AppPagination from "../components/AppPagination";
import ApiService from "../api_service/api_service";
import TextHelper from "../core/remove_tags";
import HTMLRenderer from 'react-html-renderer';
import { Link } from "react-router-dom";

const AllTechReviews = () => {

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = React.useState(0);
    const [vlogs, setVlogs] = React.useState([]);
    const [blogs, setBlogs] = React.useState([]);
    const [count, setCount] = React.useState([10]);

    React.useEffect(() => {
        const getVlogs = async () => {
            let response = await ApiService.getVlogs();
            setVlogs(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
            let res = await ApiService.getBlogs();
            setBlogs(res.data.docs);
        };
        getVlogs();
    }, [value]);

    const LoadMore = async (event, newValue) => {
        if (value === 0) {
            let response = await ApiService.getVlogs(newValue);
            setVlogs(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
        } else {
            let response = await ApiService.getBlogs(newValue);
            setBlogs(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
        }
    };

    const handleChange = async (event, newValue) => {
        setValue(newValue);
        if (value === 0) {
            let response = await ApiService.getVlogs();
            setVlogs(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
        } else {
            let response = await ApiService.getBlogs();
            setBlogs(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
        }
    };

    return (
        <Box sx={{ p: { xs: '20px', sm: '20px 100px', md: '20px 100px' }, width: { xs: '90%', sm: '70%', md: '70%' } }}>
            <Typography sx={{ fontSize: theme.palette.Title.fontSize, fontWeight: 'bold' }}>Tech Reviews</Typography>

            <Tabs value={value} onChange={handleChange}>
                <Tab label="Videos" sx={{ fontWeight: 'bold', px: '0px' }} />
                <Tab label="Articles" sx={{ fontWeight: 'bold', px: '0px', ml: '20px' }} />
            </Tabs>
            {value === 0 ? vlogs.map((article, index) => (
                <Link key={index} to={`/review-detail?content=Video&id=${article._id}`} style={{ textDecoration: 'none' }}>
                    <Card sx={{ display: 'flex', mt: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' }}>
                        <iframe
                            src={article.url}
                            frameBorder="0"
                            width={isSmallScreen ? "120" : "200"}
                            height={isSmallScreen ? "120" : "200"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Content"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: isSmallScreen ? '5px' : '20px' }}>
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileTitle.fontSize : theme.palette.SubTitle.fontSize, fontWeight: 'bold' }}>
                                {article.title}</Typography>
                            <Box height='10px' />

                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }}>
                                <HTMLRenderer html={TextHelper.makeReadMore(article.body)} />
                                <span>
                                    <Button sx={{ ml: '50px', color: 'blue', fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }} component={Link} to={`/review-detail?content=Video&id=${article._id}`}>
                                        Read more...
                                    </Button>
                                </span>
                            </Typography>
                        </Box>
                    </Card>
                </Link>
            )) : blogs.map((article, index) => (
                <Link key={index} to={`/review-detail?content=Video&id=${article._id}`} style={{ textDecoration: 'none' }}>
                    <Card key={index} sx={{ display: 'flex', mt: '20px', height: '120', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' }}>

                        <Box
                            component="img"
                            sx={{
                                overflow: 'hidden',
                            }}
                            src={ApiService.basedPublicURL + article.image}
                            style={{
                                width: isSmallScreen ? "500px" : '300px',
                                height: isSmallScreen ? '120px' : '200px',
                            }}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: '10px' }}>
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileTitle.fontSize : theme.palette.SubTitle.fontSize, fontWeight: 'bold' }}>
                                {article.title}
                            </Typography>
                            <Box height='10px' />
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }}>
                                <HTMLRenderer html={TextHelper.makeReadMore(article.body)} />
                                <span>
                                    <Button sx={{ ml: '50px', color: 'blue', fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }} component={Link} to={`/review-detail?content=Read&id=${article._id}`}>
                                        Read more...
                                    </Button>
                                </span>
                            </Typography>
                        </Box>
                    </Card>
                </Link>
            ))}
            <Box height='20px' />
            <Pagination count={count} onChange={LoadMore} color="primary" />
        </Box>
    );
}

export default AllTechReviews;
