import { Typography, Box, Button, Card, Grid } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";
import ApiService from "../../api_service/api_service";
import { Link } from "react-router-dom";
import { priceSplitor } from "../../core/price_splitor";

const PromotionPhones = (props) => {

    const offset = props.props.offset;
    const [phoneList, setPhoneList] = React.useState([]);

    React.useEffect(() => {

        const getPhoneList = async () => {
            let response = await ApiService.getPromotionPhones(offset);
            setPhoneList(response.data.docs);
        };

        getPhoneList();
    }, []);

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography sx={{
                    fontSize: theme.palette.Title.fontSize,
                    fontWeight: '600'
                }}>Promotion For You</Typography>
                <Button sx={{
                    fontSize: theme.palette.Button.fontSize
                }}
                component={Link}
                to={`/all-products?view=promotion` }

                >View All</Button>
            </Box>
            <Box height='50px' />
            <Grid container spacing={2}>
                {
                    phoneList.map((phone, index) => (
                        <Grid item xs key={index}>
                            <Card
                                sx={{
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}



                            >
                                <Box sx={{
                                    textAlign: 'center',
                                    py: '20px',

                                }}

                                    component={Link}
                                    to={`/product-detail?id=${phone._id}`}

                                >
                                    <img
                                        alt='Header Icon'
                                        loading="lazy"
                                        src={ApiService.basedPublicURL + phone.thumbnail}
                                        style={{ height: '150px', width: '150px' }}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        position: 'absolute',
                                        clipPath:
                                            'polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0)',
                                        backgroundColor: theme.palette.primary.main,
                                        width: '120px',
                                        height: '30px',
                                        top: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography sx={{
                                        color: 'white',
                                        pl: '10px',
                                        fontSize: theme.palette.SmallFont.fontSize
                                    }}>{phone.discount} OFF</Typography>
                                </Box>
                                <Box sx={{
                                    px: '20px',
                                    pb: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                    >{phone.brand.name}</Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                    >{phone.name}</Typography>
                                    <Typography sx={{
                                        pt: '10px',
                                        textAlign: 'end',
                                        // color: theme.palette.primary.main
                                    }}>{priceSplitor(phone.price)} KS</Typography>
                                    <Typography sx={{
                                        textAlign: 'end',
                                        color: theme.palette.primary.main
                                    }}>{phone.remark}</Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default PromotionPhones