import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Tab, Tabs, List, ListItem, Popover, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ApiService from "../api_service/api_service";

const TabBar = () => {
    const location = useLocation();
    const [value, setValue] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const [ categories, setCategories ] = React.useState([]);
    const [category, setCategory] = React.useState('');

    React.useEffect(() => {
     const getCategories = async () => {
            let response = await ApiService.categories();
            setCategories(response.data);
            if(categories.length > 0){
                setCategory(categories[0]);
            }
     };
     getCategories();
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath === "/all-products" || currentPath.startsWith("/product-detail")) {
            setValue(1);
        } else if (currentPath.includes("product-categories")) {
            const index = productCategoryList.findIndex(category => `/product-categories?category=${category._id}` === currentPath)
            setValue(index !== -1 ? 2 : 0);
        } else {
            const index = tabs.findIndex(tab => tab.path === currentPath);
            setValue(index !== -1 ? index : 0);
        }
    }, [location.pathname]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{
            // width: '100%',
            borderBottom: 1,
            borderColor: 'divider',
            p: '5px 80px',        
        }}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor={""}
            >
                {tabs.map((tab, index) => (
                    index === 2 ?
                        <Tab
                            label={
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{tab.label}</span>
                                        <Box sx={{
                                            ml: '5px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            }
                            aria-describedby={id}
                            onClick={handleClick}
                        /> :
                        <Tab key={index} label={tab.label} component={Link} to={tab.path} />
                ))}
            </Tabs>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: { width: '200px' }
                }}
            >
                {open && (
                    <List component="nav">
                        {categories.map((category, index) => (
                            <ListItem key={index} button component={Link} to={`/product-categories?category=${category._id}`} onClick={handleClose}>
                                <Typography variant="body1" color={location.pathname === `/product-categories/${category.name}` ? 'primary' : 'initial'}>
                                    {category.name}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Popover>
        </Box>
    );
};

const tabs = [
    { label: "Home", path: "/home" },
    { label: "All Products", path: "/all-products" },
    { label: "Product Categories", path: "/product-categories" },
    { label: "Tech Review", path: "/tech-reviews" },
    { label: "About Us", path: "/about-us" }
];

const productCategoryList = [
    { name: 'Mobiles', link: 'mobile' },
    { name: 'Tablet/iPad', link: 'tablet-ipad' },
    { name: 'Mac', link: 'mac' },
    { name: 'Wearable', link: 'wearable' },
    { name: 'Accessories', link: 'accessories' }
];

export default TabBar;
