import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react'
import ApiService from "../api_service/api_service";
import { Link } from "react-router-dom";

function CompareWith(props) {
    const name = props.props.name;
    const firstId = props.props.firstId;
    const [ phoneList, setPhoneList ] = React.useState([]);

    React.useEffect(() => {
     const getPhoneList = async () => {
            let response = await ApiService.search(name);
            setPhoneList(response.data.docs);
     };

     getPhoneList();
    }, []);

    return (
        <Box sx={{
            py: '20px',
        }}>
            {
                phoneList.map((phone) => (<Card sx={{
                    mt: '10px',
                    display: 'flex',
                    p: '20px'
                }}>
                    <img
                        alt='Header Icon'
                        loading="lazy"
                        src={ ApiService.basedPublicURL + phone.thumbnail }
                        height='70px'
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            pl: '20px'
                        }}
                    >
                        <Typography>{phone.name}</Typography>
                        <Box sx={{ alignSelf: 'flex-end' }}>
                            <Button component={Link}
                                        to={`/compare-detail?first=${firstId}&second=${phone._id}` }>Compare</Button>
                        </Box>
                    </Box>
                </Card>))
            }
        </Box>
    )
}

export default CompareWith