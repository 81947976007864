import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from "react";
import theme from "../utils/theme";
import IPhone from '../assets/images/apple-iphone-12-mini.jpg'
import ColoredCircle from '../components/ColoredCircle';
import ApiService from '../api_service/api_service';
import {priceSplitor} from "../core/price_splitor";

function DetailCompare() {

    const params = new URLSearchParams(window.location.search);
    let first = params.get('first');
    let second = params.get('second');

    const [phones, setPhones] = useState([]);
    const [phones1, setPhones1] = useState([]);
    const [phones2, setPhones2] = useState([]);

    React.useEffect(() => {
        const getPhoneList = async () => {
               let response = await ApiService.compare(first, second);
               const phoneList = response.data;
               setPhones(phoneList);
               console.log(phones[0]);
               var fPhone = phoneList[0];
               var fPhones = [];
               for(var i = 0; i < fPhone.variations.length; i++){
                   fPhones.push({
                       id: fPhone.variations[i]._id,
                       imgPath: ApiService.basedPublicURL + fPhone.variations[i].photo,
                       color: fPhone.variations[i].color,
                       colorName: fPhone.variations[i].name,
                       isSelected: i === 0 ? true : false
                   });
               }
               setPhones1(fPhones);
               
               var sPhone = phoneList[1];
               var sPhones = [];
               for(var k = 0; k < sPhone.variations.length; k++){
                   sPhones.push({
                       id: sPhone.variations[k]._id,
                       imgPath: ApiService.basedPublicURL + sPhone.variations[k].photo,
                       color: sPhone.variations[k].color,
                       colorName: sPhone.variations[k].name,
                       isSelected: k === 0 ? true : false
                   });
               }

               setPhones2(sPhones);
        };
   
        getPhoneList();
       }, []);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtralSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleSelectColor1 = (selectedColor) => {
        const updatedPhones = phones1.map(phone => ({
            ...phone,
            isSelected: phone.color === selectedColor,
        }));

        setPhones1(updatedPhones);
    }

    const handleSelectColor2 = (selectedColor) => {
        const updatedPhones = phones2.map(phone => ({
            ...phone,
            isSelected: phone.color === selectedColor,
        }));

        setPhones2(updatedPhones);
    }

    const selectedPhone1 = phones1.length == 0 ? 
    {
        id: '1',
        imgPath: IPhone,
        color: '#ff0000',
        colorName: 'Red',
        isSelected: true,
    }
    : phones1.find(phone => phone.isSelected);

        const selectedPhone2 = phones2.length == 0 ? 
    {
        id: '1',
        imgPath: IPhone,
        color: '#ff0000',
        colorName: 'Red',
        isSelected: true,
    }
    : phones2.find(phone => phone.isSelected);

    return (
        <Box sx={{
            px: { xs: '20px', sm: '100px', md: '100px' },
            pb: '70px',
            py: '20px'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            }}>
                <Box sx={{
                    width: { md: '60%' },
                    pb: { xs: '20px', sm: '20px' }
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            alt='Header Icon'
                            loading="lazy"
                            src={selectedPhone1.imgPath}
                            // style={{width: '400px', height: '400px' }}
                            // width={isExtralSmallScreen || isSmallScreen ? '40%' : ''}
                            width={isExtralSmallScreen || isSmallScreen ? '100px' : '400px'}
                        />
                    </Box>
                    <Typography sx={{
                        pt: "10px",
                        textAlign: 'center',
                        fontSize: theme.palette.SmallFont.fontSize
                    }}>{selectedPhone1.colorName}</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: '10px'
                    }}>
                        {phones1.map((phone) => (
                            <ColoredCircle
                                key={phone.id}
                                color={phone.color}
                                isSelected={phone.isSelected}
                                onClick={() => handleSelectColor1(phone.color)}
                            />
                        ))}
                    </Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: isExtralSmallScreen || isSmallScreen
                            ? theme.palette.SmallFont.fontSize
                            : theme.palette.Content.fontSize,
                        py: '10px'
                    }}>{phones.length == 0 ? "" : phones[0].brand.name + " " + phones[0].name}</Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: isExtralSmallScreen || isSmallScreen
                            ? theme.palette.Content.fontSize
                            : theme.palette.SubTitle.fontSize,
                        // color: theme.palette.primary.main
                    }}>{phones.length == 0 ? "0" : priceSplitor(phones[0].price) } Ks</Typography>
                </Box>
                <Box sx={{
                    width: { md: '40%' },
                    pb: { xs: '20px', sm: '20px' }
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            alt='Header Icon'
                            loading="lazy"
                            src={selectedPhone2.imgPath}
                            // style={{width: '400px', height: '400px' }}
                            width={isExtralSmallScreen || isSmallScreen ? '100px' : '400px'}
                        />
                    </Box>
                    <Typography sx={{
                        pt: "10px",
                        textAlign: 'center',
                        fontSize: theme.palette.SmallFont.fontSize
                    }}>{selectedPhone2.colorName}</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: '10px'
                    }}>
                        {phones2.map((phone) => (
                            <ColoredCircle
                                key={phone.id}
                                color={phone.color}
                                isSelected={phone.isSelected}
                                onClick={() => handleSelectColor2(phone.color)}
                            />
                        ))}
                    </Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: isExtralSmallScreen || isSmallScreen
                            ? theme.palette.SmallFont.fontSize
                            : theme.palette.Content.fontSize,
                        py: '10px'
                    }}>{phones.length > 1 ? phones[1].brand.name + " " + phones[1].name : ""}</Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: isExtralSmallScreen || isSmallScreen
                            ? theme.palette.Content.fontSize
                            : theme.palette.SubTitle.fontSize,
                        // color: theme.palette.primary.main
                    }}>{phones.length > 1 ? priceSplitor(phones[1].price) : ""} Ks</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'inline-block',
                bgcolor: `${theme.palette.primary.main}20`,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                textAlign: 'center',
                p: '10px'
            }}>
                <Typography sx={{
                    color: theme.palette.primary.main,
                    fontSize: theme.palette.Content.fontSize
                }}>Specification</Typography>
            </Box>
            
            <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
            {
                phones.length > 1 ? phones[0].specifications.map((spec, index) => (
                    <tr>
                        <td style={{ padding: '10px', width: '25%', verticalAlign: 'top', borderTop: '1px solid grey', borderBottom: '1px solid grey' }}>
                            <Typography sx={{ fontSize: theme.palette.Content.fontSize }}>{phones[0].specifications[index].name}</Typography>
                        </td>
                        <td style={{ padding: '10px', width: '37.5%', borderTop: '1px solid grey', borderBottom: '1px solid grey' }}>
                            <Typography sx={{ fontSize: theme.palette.Content.fontSize }}>{phones[0].specifications[index].value}</Typography>
                        </td>
                        <td style={{ padding: '10px', width: '37.5%', borderTop: '1px solid grey', borderBottom: '1px solid grey' }}>
                            <Typography sx={{ fontSize: theme.palette.Content.fontSize }}>{phones[1].specifications[index].value}</Typography>
                        </td>
                    </tr>
                )) : <tr></tr>
            }
               
            </table>
        </Box>
    )
}


export default DetailCompare