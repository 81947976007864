import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const ContentViewer = ({ contentLink }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%',
                overflow: 'hidden',
                '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <iframe
                src={`${contentLink}`}
                frameBorder="0"
                width="200"
                height="100"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Content"
            />
        </Box>
    )
}

ContentViewer.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default ContentViewer;