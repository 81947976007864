import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#ed1d24',
        },
        secondary: {
            main: '#dc004e',
        },
        logoSize: {
            larger: '50px',
            large: '40px',
        },
        logoFontsize: {
            small: '',
            large: '20px'
        },
        Title: {
            fontSize: '25px',
        },
        SubTitle: {
            fontSize: '23px',
        },
        mobileTitle: {
            fontSize: '14px',
        },
        mobileContent: {
            fontSize: '12px',
        },
        Content: {
            fontSize: '18px'
        },
        MediumFont: {
            fontSize: '16px'
        },
        SmallFont: {
            fontSize: '14px'
        },
        Button: {
            fontSize: '18px'
        },
        Icon: {
            fontSize: '35px'
        }
    }
})

export default theme