import './App.css';
import AppRouters from './routes/AppRouters';

function App() {
  return (
    <AppRouters />
  );
}

export default App;
