import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Box, FormControl, TextField, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';


const MenuDrawer = ({ categories, open, onClose }) => {
    const location = useLocation();
    const [showNested, setShowNested] = React.useState(false);
    var [name, setName] = React.useState('');
    const handleToggleNested = (event) => {
        event.stopPropagation();
        setShowNested(!showNested);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <div
                role="presentation"
            >
                <List sx={{
                    width: '300px'
                }}>
                     <Box sx={{ border: '1px solid #CCCCCC', borderRadius: '5px', display: 'flex' }}>
                            
                            <FormControl>
                            <TextField
                                variant="outlined"
                                label='Search Products ...'
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '0px', // Set border radius to 0px
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '0px !important', // Ensure the outline border radius is also 0px
                                    },
                                }}
                                onChange={(event) => {
                                    // setIsSearch(false);
                                    setName(event.target.value);
                                }}
                            />
                                {/* <Select
                                    value={category}
                                    onChange={handleChange}
                                    size='small'
                                    sx={{
                                        borderRadius: '0px', // Set border radius to 0px
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0px', // Ensure the input area border radius is 0px
                                        },
                                    }}
                                >
                                    {categories.map((value) => (
                                        <MenuItem value={value._id} key={value._id}
                                        >{value.name}</MenuItem>
                                    ))}
                                </Select> */}
                            </FormControl>
                            <Button variant="contained" sx={{ borderRadius: '0px', py: '8px' }} component={Link}  to={`/all-products?name=${name}`}>
                                <SearchIcon />
                            </Button>
                        </Box>
                    {/* <Box sx={{
                        p: '5px 20px',
                        display: 'flex'
                    }}>
                        <TextField
                            variant="outlined"
                            label='Search Products ...'
                            size="small"
                            fullWidth
                        />
                        <Button variant="contained" sx={{ borderRadius: '0px' }} onClick={onClose}>
                            <SearchIcon />
                        </Button>
                    </Box> */}
                    <ListItem key='Home' disablePadding button component={Link} to="/home" onClick={onClose}>
                        <ListItemButton>
                            <Typography variant="body1" color={location.pathname === '/home' ? 'primary' : 'initial'}>
                                Home
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key='All Products' disablePadding button component={Link} to="/all-products" onClick={onClose}>
                        <ListItemButton>
                            <Typography variant="body1" color={location.pathname === '/all-products' || location.pathname === '/product-detail' ? 'primary' : 'initial'}>
                                All Products
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key='Product Categories' disablePadding onClick={onClose}>
                        <ListItemButton onClick={handleToggleNested}>
                            <Typography variant="body1" color={location.pathname === '/product-categories' ? 'primary' : 'initial'}>
                                Product Categories
                            </Typography>
                            {showNested ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                    </ListItem>
                    {showNested && (
                        <Box sx={{ pl: '20px' }}>
                            {
                                categories.map((category) => (<ListItem key='Mobile' disablePadding button component={Link} to="/product-categories/mobile" onClick={onClose}>
                                    <ListItemButton>
                                        <Typography variant="body1" color={location.pathname === `/product-categories/${category.name}` ? 'primary' : 'initial'}>
                                            {category.name}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>))
                            }
                        </Box>
                    )}
                    <ListItem key='Tech Review' disablePadding button component={Link} to="/tech-reviews" onClick={onClose}>
                        <ListItemButton>
                            <Typography variant="body1" color={location.pathname === '/tech-reviews' ? 'primary' : 'initial'}>
                                Tech Review
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key='About Us' disablePadding button component={Link} to="/about-us" onClick={onClose}>
                        <ListItemButton>
                            <Typography variant="body1" color={location.pathname === '/about-us' ? 'primary' : 'initial'}>
                                About Us
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
}

export default MenuDrawer;
