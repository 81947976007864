import { Divider, Hidden } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import TabBar from "../components/TabBar";
import AboutUs from "../screens/AboutUs";
import AllProducts from "../screens/AllProducts";
import AllTechReviews from "../screens/AllTechReview";
import HomeScreen from "../screens/HomeScreen";
import ProductCategories from "../screens/ProductCategories";
import ProductDetail from "../screens/ProductDetail";
import TechReviewDetail from "../screens/TechReviewDetail";
import DetailCompare from "../screens/DetailCompare";
import FaqScreen from "../screens/faq_screen";


const ScreenPage = () => {
    const { page } = useParams();
    let component = <HomeScreen />;
    switch (page) {
        case '/':
            component = <HomeScreen />;
            break;
        case 'home':
            component = <HomeScreen />;
            break;
        case 'all-products':
            component = <AllProducts/>;
            break;
        case 'product-detail':
            component = <ProductDetail/>;
            break;
        case 'compare-detail':
            component = <DetailCompare/>;
            break;
        case 'product-categories':
            component = <ProductCategories />;
            break;
        case 'tech-reviews':
            component = <AllTechReviews />;
            break;
        case 'review-detail':
            component = <TechReviewDetail />;
            break;
        case 'about-us':
            component = <AboutUs />;
            break;
        case 'faq':
                component = <FaqScreen />;
                break;
        default:
            component = <HomeScreen />
            // component = <div>Invalid step</div>;
            break;
    }
    return component;
}

const AppRouters = () => {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="" element={
                    <>
                        <Header />
                        <Hidden mdDown>
                            <Divider />
                            <TabBar />
                        </Hidden>
                        <ScreenPage />
                        <Footer />
                    </>
                } />
                <Route path="/:page" element={
                    <>
                        <Header />
                        <Hidden mdDown>
                            <Divider />
                            <TabBar />
                        </Hidden>
                        <ScreenPage />
                        <Footer />
                    </>
                } />
                <Route path="/product-categories/:submenu" element={
                    <>
                        <Header />
                        <Hidden mdDown>
                            <Divider />
                            <TabBar />
                        </Hidden>
                        <ProductCategories />
                        <Footer />
                    </>
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouters