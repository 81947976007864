class TextHelper{
	static removeTags(str) {
		if ((str === null) || (str === ''))
			return false;
		else
			str = str.toString();
		return str.replace(/(<([^>]+)>)/ig, '');
	}
	
	static makeReadMore = (str) => {
		const text = str.replace(/(<([^>]+)>)/ig, '');
		if(text.length > 100){
			return text.substr(0, 99);
		}else{
			return text;
		}
	}
	
}
module.exports = TextHelper;