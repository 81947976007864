import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";

import headerIcon from '../../assets/images/logo2.png'

const SuperMobile = ({ screenWidth }) => {
    return (
        <Box sx={{
            width: screenWidth
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <img
                    src={headerIcon}
                    alt='Header Icon'
                    loading="lazy"
                    style={{
                        width: theme.palette.logoSize.larger
                    }}
                />
                <Box width='10px' />
                <Box sx={{
                    display: 'flex',
                }}>
                    <Typography sx={{
                        fontSize: theme.palette.logoFontsize.large,
                        fontWeight: 'bold',
                        color: theme.palette.primary.main
                    }}>SUPER</Typography>
                    <Box width='10px' />
                    <Typography sx={{
                        fontSize: theme.palette.logoFontsize.large,
                        fontWeight: 'bold',
                    }}>MOBILE</Typography>
                </Box>
            </Box>
            <Box height='20px'/>
            <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
        </Box>
    )
}

export default SuperMobile