import React from "react";
import { Grid, Typography, Box, Card } from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';

import IPhone from '../../assets/images/apple-iphone-12-mini.jpg'
import Huawei from '../../assets/images/huawei-nova-11i.jpg'
import Redmi from '../../assets/images/huawei-nova11-pro.jpg'
import Oppo from '../../assets/images/oppo-a1-5g-new.jpg'
import Techno from '../../assets/images/tecno-camon20-pro.jpg'
import theme from "../../utils/theme";
import { Link } from "react-router-dom";
import ApiService from "../../api_service/api_service";
import {priceSplitor} from "../../core/price_splitor";

const PhoneListGridView = (props) => {
    var phoneList = props.props.phoneList;
    return (
        <Grid container spacing={2}>
            {
                phoneList.map((phone, index) => (
                    <Grid item xs key={index}>
                        <Card
                            key={index}
                            sx={{
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                textDecoration: 'none'
                            }}
                            component={Link}
                            to={`/product-detail?id=${phone._id}`}
                            
                        >
                            <Box sx={{
                                textAlign: 'center',
                                p: '20px'
                            }}>
                                <img
                                    alt='Header Icon'
                                    loading="lazy"
                                    src={ ApiService.basedPublicURL + phone.thumbnail }
                                    style={{ height: '150px', width: '150px' }}
                                />
                            </Box>
                            <Box sx={{
                                px: '20px',
                                pb: '20px',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                            }}>
                                <Typography
                                 sx={{
                                    fontWeight: 600,
                                }}
                                >{phone.brand.name}</Typography>
                                <Typography
                                sx={{
                                    fontWeight: 600,
                                }}
                                >{phone.name}</Typography>
                                <Typography sx={{
                                    pt: '10px',
                                    // color: theme.palette.primary.main,
                                    fontSize: theme.palette.SmallFont.fontSize
                                }}>{ priceSplitor(phone.price) } Ks</Typography>
                                <Box height='10px' />
                                {phone.remark}
                                {/* {
                                    phone.inStock ?
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end'
                                        }}>
                                            <CircleIcon sx={{
                                                fontSize: '13px',
                                                color: 'green'
                                            }} />
                                            <Box width='10px' />
                                            <Typography sx={{
                                                fontSize: theme.palette.SmallFont.fontSize
                                            }}>In stock</Typography>
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end'
                                        }}>
                                            <CircleIcon sx={{
                                                fontSize: '13px',
                                            }} />
                                            <Box width='10px' />
                                            <Typography sx={{
                                                fontSize: theme.palette.SmallFont.fontSize
                                            }}>Sold out</Typography>
                                        </Box>
                                } */}
                            </Box>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default PhoneListGridView