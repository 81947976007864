import axios from 'axios'

const devDomain = "http://localhost:8080";
const productionDomain = "https://supermobile.com.mm";
const basedURL = productionDomain;

const instance = axios.create({
    baseURL: `${basedURL}/client/api-v1/`,
    headers: {
        // 'Access-Control-Allow-Origin': '*'
    }
});

const defaultLimit = 12;
const defaultOffset = 0;

export default class ApiService {
    static basedPublicURL = `${basedURL}/admin/`;

    //Get All Banners
    static async banner() {
        return await instance({
            'method': 'GET',
            'url': `banners`,
        });
    }

    //Get All Ads
    static async ads() {
        return await instance({
            'method': 'GET',
            'url': `ads`,
        });
    }

    //Get All Ads
    static async categories() {
        return await instance({
            'method': 'GET',
            'url': `categories`,
        });
    }

    static async products(offset = defaultOffset, limit = defaultLimit) {

        return await instance({
            'method': 'GET',
            'url': `products?offset=${offset}&limit=${limit}`,
        });
    }

    static async productById(id) {
        return await instance({
            'method': 'GET',
            'url': `product?${id}`,
        });
    }

    static async compare(first, second) {
        return await instance({
            'method': 'GET',
            'url': `compare?first=${first}&second=${second}`,
        });
    }

    static async search(name, offset = defaultOffset, limit = defaultLimit) {
        return await instance({
            'method': 'GET',
            'url': `search?name=${name}&offset=${offset}&limit=6`,
        });
    }

    static async searchWithCategory(categoryId, name, offset = defaultOffset, limit = defaultLimit) {
        return await instance({
            'method': 'GET',//categoryId=${categoryId}&
            'url': `search?name=${name}&offset=${offset}&limit=6`,
        });
    }

    static async productsByCategory(categoryId, offset = defaultOffset, limit = defaultLimit) {

        return await instance({
            'method': 'GET',
            'url': `products/${categoryId}?offset=${offset}&limit=${limit}`,
        });
    }

    static async brands() {
        return await instance({
            'method': 'GET',
            'url': 'brands',
        });
    }

    static async specs() {
        return await instance({
            'method': 'GET',
            'url': 'specs',
        });
    }

    static async getProducts() {
        return await instance({
            'method': 'GET',
            'url': 'products',
        });
    }

    static async getNewPhones(offset = defaultOffset, limit = 6) {
        return instance({
            'method': 'GET',
            'url': `new-phones?offset=${offset}&limit=${limit}`,
        });
    }

    static async getPromotionPhones(offset = defaultOffset, limit = 6) {
        return await instance({
            'method': 'GET',
            'url': `promotion-phones?offset=${offset}&limit=${limit}`,
        })
    }

    static async getBestsellerPhones(offset = defaultOffset, limit = 6) {
        return await instance({
            'method': 'GET',
            'url': `bestseller-phones?offset=${offset}&limit=${limit}`,
        });
    }


    static async getBlogs(offset = defaultOffset, limit = 3) {
        return await instance({
            'method': 'GET',
            'url': `blogs?offset=${offset}&limit=${limit}`,
        });
    }

    static async getBlogById(id) {
        return await instance({
            'method': 'GET',
            'url': `blog?id=${id}`,
        });
    }

    static async getVlogs(offset = defaultOffset, limit = 3) {
        return await instance({
            'method': 'GET',
            'url': `vlogs?offset=${offset}&limit=${limit}`,
        });
    }

    static async getVlogById(id) {
        return await instance({
            'method': 'GET',
            'url': `vlog?id=${id}`,
        });
    }

    postData() {
        return instance({
            'method': 'POST',
            'url': '/api',
            'data': {
                'item1': 'data1',
                'item2': 'item2'
            },
            'headers': {
                'content-type': 'application/json'
            }
        });
    }
}
