import { Box, Button, Card, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import HTMLRenderer from 'react-html-renderer'
import theme from "../../utils/theme";
import ApiService from "../../api_service/api_service";
import TextHelper from "../../core/remove_tags";
import { Link } from "react-router-dom";

const TechReviews = (props) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = React.useState(0);
    const [blogs, setBlogs] = React.useState([]);
    const [vlogs, setVlogs] = React.useState([]);

    React.useEffect(() => {

        const getArticles = async () => {
            let responseVlog = await ApiService.getVlogs();
            setVlogs(responseVlog.data.docs);
            let responseBlog = await ApiService.getBlogs();
            console.log(responseBlog);
            setBlogs(responseBlog.data.docs);
        };

        getArticles();
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box>
            <Typography sx={{
                fontSize: theme.palette.Title.fontSize,
                fontWeight: 'bold'
            }}>Tech Reviews</Typography>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Video Contents" sx={{
                    fontWeight: 'bold',
                    px: '0px'
                }} />
                <Tab label="Articles Contents" sx={{
                    fontWeight: 'bold',
                    px: '0px',
                    ml: '20px'
                }} />
            </Tabs>
            {value === 0 ? vlogs.map((article, index) => (
                <Link key={index} to={`/review-detail?content=Video&id=${article._id}`} style={{ textDecoration: 'none' }}>
                    <Card sx={{ display: 'flex', mt: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' }}>
                        <iframe
                            src={article.url}
                            frameBorder="0"
                            width={isSmallScreen ? "120" : "200"}
                            height={isSmallScreen ? "120" : "200"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Content"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: isSmallScreen ? '5px' : '20px' }}>
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileTitle.fontSize : theme.palette.SubTitle.fontSize, fontWeight: 'bold' }}>
                                {article.title}</Typography>
                            <Box height='10px' />

                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }}>
                                <HTMLRenderer html={TextHelper.makeReadMore(article.body)} />
                                <span>
                                    <Button sx={{ ml: '50px', color: 'blue', fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }} component={Link} to={`/review-detail?content=Video&id=${article._id}`}>
                                        Read more...
                                    </Button>
                                </span>
                            </Typography>
                        </Box>
                    </Card>
                </Link>
            )) : blogs.map((article, index) => (
                <Link key={index} to={`/review-detail?content=Video&id=${article._id}`} style={{ textDecoration: 'none' }}>
                    <Card key={index} sx={{ display: 'flex', mt: '20px', height: '120', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' }}>

                        <Box
                            component="img"
                            sx={{
                                overflow: 'hidden',
                            }}
                            src={ApiService.basedPublicURL + article.image}
                            style={{
                                width: isSmallScreen ? "500px" : '300px',
                                height: isSmallScreen ? '120px' : '200px',
                            }}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: '10px' }}>
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileTitle.fontSize : theme.palette.SubTitle.fontSize, fontWeight: 'bold' }}>
                                {article.title}
                            </Typography>
                            <Box height='10px' />
                            <Typography sx={{ fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }}>
                                <HTMLRenderer html={TextHelper.makeReadMore(article.body)} />
                                <span>
                                    <Button sx={{ ml: '50px', color: 'blue', fontSize: isSmallScreen ? theme.palette.mobileContent.fontSize : theme.palette.Content.fontSize }} component={Link} to={`/review-detail?content=Read&id=${article._id}`}>
                                        Read more...
                                    </Button>
                                </span>
                            </Typography>
                        </Box>
                    </Card>
                </Link>
            ))}
            <Box sx={{
                display: 'flex',
                pt: '20px',
                justifyContent: 'end'
            }}>
                <Button variant="contained" sx={{
                    px: '30px'
                }}
                component={Link}
                to={`/tech-reviews`}
                >Read All</Button>
            </Box>
        </Box>
    )
}

export default TechReviews