import { Box, Typography } from '@mui/material'
import React from 'react'
import ContentViewer from '../components/ContentViewer'
import theme from "../utils/theme";
import ApiService from '../api_service/api_service';
import HTMLRenderer from 'react-html-renderer'

function TechReviewDetail() {
    const params = new URLSearchParams(window.location.search);
    let id = params.get('id');
    let type = params.get('content');
    const [article, setArticle] = React.useState({});
    React.useEffect(() => {
        
        const getArticle = async () => {
            if(type == "video" || type == "Video"){
                let response = await ApiService.getVlogById(id);
                setArticle(response.data);
                console.log(response.data);
            }else{
                let response = await ApiService.getBlogById(id);
                setArticle(response.data);
                console.log(response.data);
            }
        };
        getArticle();
    }, []);

    return (
        <Box sx={{
            p: { xs: '20px', sm: '20px 100px', md: '20px 100px' },
            width: { xs: '90%', sm: '70%', md: '70%' },
        }}>
            {
                type == "Video" ? (<ContentViewer contentLink={article.url} />) : (<img
                    alt='Content Photo'
                    loading="lazy"
                    src={ApiService.basedPublicURL + article.image}
                    style={{width: '100%' }}
                />)
            }

            <Typography sx={{
                fontSize: theme.palette.SubTitle.fontSize,
                fontWeight: 'bold',
                mt: '20px'
            }}>{article.title}</Typography>
            <Typography sx={{
                fontSize: theme.palette.Content.fontSize
            }}>
                <HTMLRenderer
                    html={article.body} />
            </Typography>
        </Box>
    )
}

export default TechReviewDetail