import { Box, Card, Hidden, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../utils/theme";
import ShopInfo from "./footer_components/ShopInfo";
import SuperMobile from "./footer_components/SuperMobile";
import UsefulLink from "./footer_components/UsefulLink";

const Footer = () => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Card sx={{
            pt: '50px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)'
        }}>
            <Box sx={{
                px: isSmallScreen ? "20px" : '100px',
            }}>
                <Hidden mdDown>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <SuperMobile screenWidth='50%' />
                        <Box width='50px' />
                        <UsefulLink />

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        pt: '50px'
                    }}>
                        <ShopInfo />
                    </Box>
                </Hidden>
                <Hidden mdUp>
                    <SuperMobile />
                    <Box height='30px' />
                    <UsefulLink />
                    <Box sx={{
                        pt: '30px'
                    }}>
                        <ShopInfo />
                    </Box>
                </Hidden>

            </Box>
            <Box sx={{
                display: 'flex',
                bgcolor: 'black',
                justifyContent: 'center',
                alignItems: 'center',
                py: '15px'
            }}>
                <Typography sx={{
                    color: 'white'
                }}>Copyright © SuperMobile</Typography>
                <Box
                    sx={{
                        height: '20px',
                        width: '3px',
                        bgcolor: theme.palette.primary.main,
                        display: 'inline-block',
                        mx: '5px'
                    }}
                />
                <Typography sx={{
                    color: 'white'
                }}>Developed by App4U</Typography>
            </Box>
        </Card>
    )
}

export default Footer