import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import theme from '../utils/theme.jsx';
import HomeAboutUs from "../components/home_components/HomeAboutUs";
import AdsCarousel from "../components/home_components/AdsCarousel";
import BestSellerPhones from "../components/home_components/BestSellerPhones";
import MobileBrands from "../components/home_components/MobileBrands";
import NewPhones from "../components/home_components/NewPhones";
import PromotionPhones from "../components/home_components/PromotionPhones";
import TechReviews from "../components/home_components/TechReviews";

const HomeScreen = () => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const [ categories, setCategories ] = React.useState([]);
    // const [category, setCategory] = React.useState('');

    // React.useEffect(() => {
    //  const getCategories = async () => {
    //         let response = await ApiService.categories();
    //         setCategories(response.data);
    //         if(categories.length > 0){
    //             setCategories(categories[0]);
    //         }
    //  };
    //  getCategories();
    // }, []);

    return (
        <>
            <AdsCarousel/>
            <Box height='50px'/>
            <Box sx={{
                px: isSmallScreen ? '20px' : '100px'
            }}>
                <MobileBrands/>
                <Box height='50px'/>
                <NewPhones props={{ offset: 0 }} />
                <Box height='50px'/>
                <PromotionPhones props={{ offset: 0 }}/>
                <Box height='50px'/>
                <BestSellerPhones props={{ offset: 0 }}/>
                <Box height='50px'/>
                <HomeAboutUs/>
                <Box height='50px'/>
                <TechReviews/>
                <Box height='50px'/>
            </Box>
        </>
    )
}

export default HomeScreen