import React from 'react';

const ColoredCircle = ({ color, isSelected, onClick }) => {
  const circleStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    boxShadow: '1px 3px 10px #b5aba8',
    backgroundColor: isSelected ? color : `${color}50`,
    border: isSelected ? `1px solid ${color}` : 'none',
    display: 'inline-block',
    cursor: 'pointer',
    margin: '5px',
  };

  return <div style={circleStyle} onClick={onClick}></div>;
};

export default ColoredCircle;
