import React, { useState } from "react";
import { Box, Grid, Typography, IconButton, Hidden, useMediaQuery, Popover } from "@mui/material";
import ProductCategoriesFilter from "../components/product_categories_components/ProductCategoriesFilter";
import ProductList from "../components/all_products_components/ProductList";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useParams } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import theme from '../utils/theme.jsx';
import ApiService from "../api_service/api_service";
import ProductFilter from "../components/all_products_components/ProductFilter.jsx";

const ProductCategories = () => {

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{
            px: isSmallScreen ? '20px' : '100px',
            pb: '70px'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '10px'
            }}>
                <Box sx={{
                    display: 'flex',
                    p: '10px'
                }}>
                    <Typography>Products</Typography>
                    <Box width='5px' />
                    <KeyboardDoubleArrowRightIcon />
                    <Box width='5px' />
                    <Typography></Typography>
                </Box>
                <Hidden mdUp>
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Hidden>
            </Box>
            <Hidden mdDown>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <ProductFilter />
                    </Grid>
                    <Grid item xs={9}>
                        <ProductList />
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <ProductList />
            </Hidden>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: { width: '250px' }
                }}
            >
                {open && (
                    <ProductCategoriesFilter />
                )}
            </Popover>
        </Box>
    )
}

export default ProductCategories