import { Box, Card, FormControl, IconButton, InputLabel, MenuItem, Pagination, Select, Typography } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";

import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PhoneListGridView from "./PhoneListGridView";
import AppPagination from "../AppPagination";
import { useLocation } from 'react-router-dom';
import PhoneListView from "./PhoneListView";
import ApiService from "../../api_service/api_service";

const ProductList = () => {
    const params = new URLSearchParams(window.location.search);
    let name = params.get('name');
    let categoryId = params.get('category');
    let view = params.get('view');

    console.log("CATEGORY: " + categoryId);

    const [count, setCount] = React.useState([10]);
    const [phoneList, setPhoneList] = React.useState([]);

    React.useEffect(() => {
        const getPhoneList = async () => {
            let response;
            if (name) {
                response = await ApiService.searchWithCategory("", name);
                // if (category) {
                //     // response = await ApiService.searchWithCategory(categoryId, name);
                //     response = await ApiService.productsByCategory(category);
                // } else {
                //     // response = await ApiService.productsByCategory(categoryId);
                //     response = await ApiService.searchWithCategory("", name);
                // }
            } else if(categoryId){
                response = await ApiService.productsByCategory(categoryId);
            } else {
                if (view) {
                    if (view == 'promotion') {
                        response = await ApiService.getPromotionPhones();
                    } else if (view == 'bestseller') {
                        response = await ApiService.getBestsellerPhones();
                    } else if (view == 'new') {
                        response = await ApiService.getNewPhones();
                    } else {
                        response = await ApiService.products();
                    }
                } else {
                    response = await ApiService.products();
                }
            }

            setPhoneList(response.data.docs);
            let total = response.data.total === 0 ? 1 : response.data.total;
            let limit = response.data.limit === 0 ? 1 : response.data.limit;
            let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
            setCount(totalCount);
        };

        getPhoneList();
    }, [categoryId, name, view]);


    const LoadMore = async (event, value) => {
        let response;
        if (categoryId) {
            if (name) {
                response = await ApiService.searchWithCategory(categoryId, name, value, 12);
            } else {
                response = await ApiService.productsByCategory(categoryId, value, 12);
            }
        } else {
            if (view) {
                if (view == 'promotion') {
                    response = await ApiService.getPromotionPhones(value, 12);
                } else if (view == 'bestseller') {
                    response = await ApiService.getBestsellerPhones(value, 12);
                } else if (view == 'new') {
                    response = await ApiService.getNewPhones(value, 12);
                } else {
                    response = await ApiService.products(value, 12);
                }
            } else {
                response = await ApiService.products(value, 12);
            }
        }

        setPhoneList(response.data.docs);
        let total = response.data.total === 0 ? 1 : response.data.total;
        let limit = response.data.limit === 0 ? 1 : response.data.limit;
        let totalCount = parseInt(total / limit) === 0 ? parseInt(total / limit) + 1 : parseInt(total / limit);
        setCount(totalCount);
    };


    let category = '';

    const location = useLocation()
    const path = location.pathname

    const parts = path.split('/');
    const lastPart = parts[parts.length - 1];

    switch (lastPart) {
        case 'all-products':
            category = 'All Products';
            break;
        case 'mobile':
            category = 'Mobile';
            break;
        case 'tablet-ipad':
            category = 'Tablet/iPad';
            break;
        case 'mac':
            category = 'Mac';
            break;
        case 'wearable':
            category = 'Wearable';
            break;
        case 'accessories':
            category = 'Accessories';
            break;
        default:
            category = '';
            break;
    }


    const [layout, setLayout] = React.useState('box');

    const SortForm = () => {
        const [sort, setSort] = React.useState('');

        const handleSort = (event) => {
            const sortValue = event.target.value;
            phoneList.sort(function (a, b) {
                if (sortValue == 1) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                }else if(sortValue == 2){
                    if (a.name < b.name) {
                        return 1;
                    }
                    if (a.name > b.name) {
                        return -1;
                    }
                }else if(sortValue == 3){
                    if (a.price < b.price) {
                        return 1;
                    }
                    if (a.price > b.price) {
                        return -1;
                    }
                }else if(sortValue == 4){
                    if (a.price < b.price) {
                        return -1;
                    }
                    if (a.price > b.price) {
                        return 1;
                    }
                }
                return 0;
            });
            setPhoneList(phoneList);
            setSort(event.target.value);
            console.log(phoneList[0]._id);
            console.log(phoneList[4]._id);
        };
        return (
            <FormControl sx={{ width: '150px' }} size='small'>
                <InputLabel id="demo-simple-select-label" sx={{
                    color: theme.palette.primary.main
                }}>Sort by</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sort}
                    label='Sort by'
                    onChange={handleSort}
                    sx={{
                        '&:hover': {
                            '& .MuiSelect-icon, & .MuiSelect-root': {
                                color: theme.palette.primary.main, // Changing the icon and text color on hover
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main, // Changing the border color on hover
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.primary.main, // Changing the default border color
                        },
                        '&:focus': {
                            '& .MuiSelect-icon, & .MuiSelect-root': {
                                color: theme.palette.primary.main, // Changing the icon and text color on focus
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main, // Changing the border color on focus
                            },
                        },
                        '& .MuiSelect-icon, & .MuiSelect-root': {
                            color: theme.palette.primary.main, // Changing the default icon and text color
                        },
                    }}
                >
                    <MenuItem value={1}>Name(A to Z)</MenuItem>
                    <MenuItem value={2}>Name(Z to A)</MenuItem>
                    <MenuItem value={3}>Price (low to high)</MenuItem>
                    <MenuItem value={4}>Price (high to low)</MenuItem>
                </Select>
            </FormControl>
        )
    }

    return (

        
        <Card sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            p: '20px 50px'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box
                    sx={{
                        height: '30px',
                        width: '3px',
                        bgcolor: theme.palette.primary.main,
                        display: 'inline-block',
                        mr: '10px'
                    }}
                />
                <Typography sx={{
                    fontSize: theme.palette.Content.fontSize,
                    fontWeight: 'bold'
                }}>{category}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: { xs: 'start', md: 'end' },
                alignItems: 'center',
                py: '10px'
            }}>
                <SortForm />
                <Box width='15px' />
                <IconButton
                    color={layout === 'box' ? "primary" : ''}
                    onClick={() => {
                        setLayout('box');
                    }}
                >
                    <GridViewIcon sx={{
                        fontSize: theme.palette.Icon.fontSize,
                    }} />
                </IconButton>
                <IconButton
                    color={layout === 'list' ? "primary" : ''}
                    onClick={() => {
                        setLayout('list');
                    }}
                >
                    <FormatListBulletedIcon sx={{
                        fontSize: theme.palette.Icon.fontSize,
                    }} />
                </IconButton>
            </Box>
            <Box height='20px' />
            {
                layout === 'box' ?
                    <PhoneListGridView props={{ phoneList }} /> :
                    <PhoneListView props={{ phoneList }} />
            }
            <Box height='20px' />
            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Pagination count={count} onChange={LoadMore} color="primary" />
            </Box>
        </Card>
    )
}



export default ProductList