import { Card, Checkbox, Chip, Collapse, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from '../../utils/theme'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ApiService from "../../api_service/api_service";
import { Link } from "react-router-dom";

const brandList = [
    'IPhone',
    'Samsung',
    'Xiao Mi',
    'Redmi',
    'Hawei',
    'Nokia'
];

const priceList = ['Under 1k', '1k - 2k', '2k - 3k']

const ProductCategoriesFilter = () => {

    
    const [ categories, setCategories ] = React.useState([]);
    const [ brands, setBrands ] = React.useState([]);

    React.useEffect(() => {
    const getCategories = async () => {
            let catRes = await ApiService.categories();
            setCategories(catRes.data);
            let brandRes = await ApiService.brands();
            setBrands(brandRes.data);
    };
    getCategories();
    }, []);

    const handleDelete = (item) => {
        setFilters(filters.filter((selected) => selected !== item));
    };

    const [brandsOpen, setBrandsOpen] = useState(false);
    const [categoriesOpen, setCategoriesOpen] = useState(false);
    const [priceOpen, setPriceOpen] = useState(false);
    const [filters, setFilters] = useState([]);

    const handleBrandsClick = () => {
        setBrandsOpen(!brandsOpen);
    };

    const handlePriceClick = () => {
        setPriceOpen(!priceOpen);
    };

    const handleCategoriesClick = () => {
        setCategoriesOpen(!categoriesOpen);
    };

    const handleFilterClick = (item) => () => {
        if (filters.includes(item)) {
            setFilters(filters.filter((selected) => selected !== item));
        } else {
            // setFilters([...filters, item]);
            setFilters([item]);
        }
    };


    return (
        <Card sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}>
            <Typography sx={{
                fontSize: theme.palette.SubTitle.fontSize,
                fontWeight: 'W500',
                px: '10px',
                pt: '20px'
            }}>Filters</Typography>
            {
                filters.map((filterItem, index) => (
                    <Chip
                        key={index}
                        label={filterItem}
                        variant="outlined"
                        onDelete={() => handleDelete(filterItem)}
                        color='primary'
                        sx={{
                            m: '5px',
                        }}
                    />
                ))
            }
            <ListItem button onClick={handleBrandsClick}>
                <ListItemText primary='Brand' />
                {brandsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItem>
            <Collapse in={brandsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {brandList.map((brand) => (
                        <ListItem key={brand} button onClick={handleFilterClick(brand)}>
                            <Checkbox checked={filters.includes(brand)} />
                            <ListItemText primary={brand} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
            
            <ListItem button onClick={handleCategoriesClick}>
                <ListItemText primary='Category' />
                {categoriesOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItem>
            <Collapse in={categoriesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {categories.map((category) => (
                        <ListItem key={category._id} component={Link} to={`/product-categories?category=${category._id}`}  button onClick={handleFilterClick(category.name)}>
                            <Checkbox checked={filters.includes(category.name)}/>
                            <ListItemText primary={category.name} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
            
            <Collapse in={priceOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {priceList.map((price) => (
                        <ListItem key={price} button onClick={handleFilterClick(price)}>
                            <Checkbox checked={filters.includes(price)} />
                            <ListItemText primary={price} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </Card>
    )
}

export default ProductCategoriesFilter