import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const data = {
    title: "FAQ",
    rows: [
        {
            title: " Handset နဲ့ Accessories တွေကို လက္ကားဈေး (အများဈေး) နဲ့ ဝယ်ယူလို့ရပါသလား?",
            content: `Handset နဲ့ Accessories တွေကို လက္ကားဈေးနဲ့ ဝယ်ယူလို့ရပါတယ်။`,
        },
        {
            title: "ရန်ကုန်ဆိုင်ရဲ့ လိပ်စာနဲ့ ဖုန်းနံပါတ်?",
            content:
                `ရန်ကုန်ဆိုင်ရဲ့ လိပ်စာနဲ့ ဖုန်းနံပါတ်လေးက -<br>
                <b>𝐒𝐮𝐩𝐞𝐫 𝐌𝐨𝐛𝐢𝐥𝐞 (𝐘𝐚𝐧𝐠𝐨𝐧)</b><br>
                Room (201-A1), No. (192), 2nd Floor, Myanmar Plaza, Kabaraye Pagoda Road, Yankin Township, Yangon.<br>
                Phone - 09-448490007, 09-452352555
                `,
        },
        {
            title: "Handset တွေကို အရစ်ကျစနစ်နဲ့ ဝယ်ယူလို့ရပါသလား? ရရင် ဘာတွေလိုအပ်ပါသလဲ?",
            content: `
            Handset တွေကို<br>

            Rent to Own (R2O) အတိုးမဲ့အရစ်ကျစနစ်
            မဟာဘောဂ စတဲ့ အရစ်ကျစနစ်တွေနဲ့ ဝယ်ယူလို့ရပါတယ်။ 

            အရစ်ကျစနစ်နဲ့ ဝယ်ယူဖို့ လျှောက်ထားလိုပါက -<br>

            <b>Rent to Own အတိုးမဲ့ အရစ်ကျစနစ်</b><br>

            လက်ငင်းပေးသွင်းငွေ - 20% to 80%<br>

            ဝန်ဆောင်ခ - 6%<br>

            (ကျန်ငွေကို ၅ လ အရစ်ကျပေးသွင်းရမှာပါ။)<br><br>

            လိုအပ်သောစာရွက်စာတမ်းများ<br>

            <b>လျှောက်ထားသူ</b><br>

            - မှတ်ပုံတင် (မူရင်း)<br>

            - အိမ်ထောင်စုစာရင်း (မူရင်း)<br>

            - ၂ ပတ်အတွင်းပြုလုပ်ထားသော ရပ်ကွက်ထောက်ခံစာ<br>

            - အိမ်သုံးအခွန်စာရွက်တစ်မျိုးမျိုး (ဥပမာ - မီတာဘေလ်)<br><br>


            <b>အာမခံမည့်သူ</b><br>

            - မှတ်ပုံတင် (မူရင်း)<br>

            - အိမ်ထောင်စုစာရင်း (မူရင်း)<br>

            (လက်ငင်းပေးသွင်းငွေ ၄၀% နှင့်အထက်ဆိုလျှင် အာမခံပေးမည့်သူမလိုအပ်ပါ)<br>

            အသေးစိတ်ချက်လက်များတွက် 09-444 688 686 ကို ဆက်သွယ်မေးမြန်းနိုင်ပါတယ်။<br><br>

            <b>မဟာဘောဂ အရစ်ကျအစီအစဉ်</b><br>

            လက်ငင်းပေးသွင်းငွေ - 20%<br>

            ဝန်ဆောင်ခ - 7%<br>

            တစ်လချင်းအတိုးနှုန်း - 3.8 %<br>

            (ကျန်ငွေကို  ၆လ၊ ၉လ၊ ၁၂လ စသဖြင့် ကြိုက်နှစ်သက်သလို အရစ်ကျပေးသွင်းနိုင်ပါသည်။)<br><br>


            လိုအပ်ချက်သောစာရွက်စာတမ်းများ<br>

            <b>လျှောက်ထားမည့်သူ</b><br>

            - မှတ်ပုံတင် (မူရင်း)<br>

            - အိမ်ထောင်စုစာရင်း (မူရင်း)<br>

            - အလုပ်ထောက်ခံစာ (မူရင်း)<br>

            - ရက်ကွက်ထောက်ခံစာ မူရင်း (သို့မဟုတ်) မီတာ‌ဘေလ်စာရွက်<br>

            (လစာ 400,000 ကျပ် အထက်ဖြစ်ပါက Pay Slip လိုအပ်ပါမည်။)<br>

            (ကိုယ်ပိုင်လုပ်ငန်းဖြစ်ပါက လုပ်ငန်းလိုင်စင်၊ အခွန်ပြေစာ (သို့မဟုတ်) လုပ်ငန်းလုပ်ကိုင်နေကြောင်း ရပ်ကွက်ထောက်ခံစာ နှင့် ဆိုင်ဓာတ်ပုံ ၃ ပုံ)<br><br>

            <b>အာမခံပေးမည့်သူ</b><br>

            - မှတ်ပုံတင် (မူရင်း)<br>

            - အိမ်ထောင်စုစာရင်း (မူရင်း)<br>

            - ဝန်ထမ်းကဒ် (မူရင်း)<br>

            (ကိုယ်ပိုင်လုပ်ငန်းဖြစ်ပါက လုပ်ငန်းလိုင်စင်၊ အခွန်ပြေစာ (သို့မဟုတ်) လုပ်ငန်းလုပ်ကိုင်နေကြောင်း ရပ်ကွက်ထောက်ခံစာ နှင့် ဆိုင်ဓာတ်ပုံ ၃ ပုံ)<br>

            `,
        },
        {
            title: "ဆိုင်ပိတ်ချိန် နဲ့ ဆိုင်ဖွင့်ချိန်သိပါရစေ။",
            content: `ဆိုင်ဖွင့်ချိန်က မနက် 08:30 AM ဖြစ်ပြီး ညနေ 06:30 PM မှာ ပိတ်ပါတယ်။`,
        },
        {
            title: "SIM Card/B2B Plan Card/Bill Card လက္ကားဈေးနှုန်းနဲ့ ဝယ်လို့ရနိုင်ပါသလား?",
            content: `SIM Card/B2B Plan Card/Bill Card ကို လက္ကားဈေးနဲ့ ဝယ်ယူလို့ရပါတယ်။`,
        },
        {
            title: "Super Mobile က ဝယ်ထားတာမဟုတ်တဲ့ ဖုန်းတွေကို Service ပြလို့ရပါသလား?",
            content: `မည်သည့်ဆိုင်က ဝယ်ယူထားသည်ဖြစ်စေ၊ Super Mobile မှာ Service ပြလို့ရပါတယ်။ မိမိနဲ့ နီးစပ်ရာ Super Mobile ဆိုင်ခွဲတွေမှာ သွားရောက် ပြသနိုင်ပါတယ်။`,
        },
        {
            title: "နယ်ပို့ပေးပါသလား? ပစ္စည်းရောက်မှ ငွေချေလို့ရော နိုင်မလား?",
            content: `နယ်မြို့ကနေ လှမ်းဝယ်တယ်ဆိုရင် ငွေကြိုရှင်းပေးဖို့ လိုအပ်ပြီး နီးစပ်ရာကားဂိတ်အထိရောက်အောင် ဂိတ်တင်ပေးပါတယ်။`,
        },
        {
            title: "မန္တလေးမြို့တွင်း အိမ်အရောက်ပို့ပေးပါသလား?",
            content: `ကျပ် 30,000 ဖိုးနဲ့အထက် ဝယ်ယူတာနဲ့ မန္တလေးမြို့တွင်း အိမ်အရောက် အခမဲ့ပို့ဆောင်ပေးပါတယ်။ `,
        },

        {
            title: "eSIM လျှောက်လို့ရပါသလား?",
            content: `Super Mobile မှာ eSIM လာရောက် လျှောက်ထားလို့ရပါတယ်။`,
        },
        {
            title: "SIM Card တွေ ပျောက်ဆုံးသွားတာမျိုးဆိုရင် လျှောက်ပေးပါသလား?",
            content: `SIM Card ပျောက်ဆုံးတာမျိုးကို Super Mobile မှာ လာရောက်လျှောက်ထားလို့ရနိုင်ပါတယ်။ `,
        },
        {
            title: "SIM Card မှတ်ပုံတင်တာရော ဝန်ဆောင်မှုပေးပါသလား?",
            content: `SIM Card မှတ်ပုံတင်တာကို Super Mobile က ဆောင်ရွက်ပေးပါတယ်။`,
        },
        {
            title: "Online Banking နဲ့ ငွေချေလို့ရပါသလား?",
            content: `
                    Super Mobile မှာ<br>

                    Smartphones & Tablets နဲ့ Accessories ကြိုက်နှစ်သက်ရာ အမျိုးအစားတွေကို ဝယ်ယူတဲ့အခါ
                    ရာခိုင်နှုန်း အပိုပေးစရာမလိုဘဲ 100% Online Payment အမျိုးမျိုးနဲ့ ပေးချေလို့ရပါတယ်။<br>

                    🔹<b>KBZ Pay, AYA Pay, A Plus, UAB Pay</b> တို့အပြင်<br>
                    🔹 <b>MPU CARD, VISA Card, Mastercard</b> အစရှိတဲ့ Card တွေနဲ့ စိတ်ကြိုက်ပေးချေ ဝယ်ယူလို့ရ ပါတယ်။ `,
        },
    ],
};

const styles = {
    // bgColor: 'white',
    // titleTextColor: "blue",
    // rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const FaqScreen = () => {

    return (
        <Box  sx={{
            p: { xs: '20px', sm: '20px 50px', md: '50px' },
        }}>
            <div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </Box>
    );
}

export default FaqScreen