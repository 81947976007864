import React from "react";
import { Typography, Box, Card } from "@mui/material";
import { Link } from "react-router-dom";
import ApiService from "../../api_service/api_service";
import CircleIcon from '@mui/icons-material/Circle';
import IPhone from '../../assets/images/apple-iphone-12-mini.jpg'
import theme from "../../utils/theme";
import { priceSplitor } from "../../core/price_splitor";

const PhoneListView = (props) => {
    var phoneList = props.props.phoneList;
    return (
        phoneList.map((phone, index) => (
            <Card
                key={index}
                sx={{
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    mb: '20px'
                }}
                component={Link}
                to={`/product-detail?id=${phone._id}`}
            >
                <Box sx={{
                    textAlign: 'center',
                    p: '20px',
                }}>
                    <img
                        alt='Header Icon'
                        loading="lazy"
                        src={ApiService.basedPublicURL + phone.thumbnail}
                        style={{ height: 'auto', maxWidth: '100px' }}
                    />
                </Box>
                <Box sx={{
                    p: '20px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                        }}
                    >{phone.brand.name}</Typography>
                    <Typography
                        sx={{
                            fontWeight: 600,
                        }}
                    >{phone.name}</Typography>
                    <Typography sx={{
                        pt: '10px',
                        fontSize: theme.palette.Content.fontSize
                    }}>{priceSplitor(phone.price)} Ks</Typography>

                    <Box height='10px' />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <CircleIcon sx={{
                            fontSize: '13px',
                            color: 'green'
                        }} />
                        <Box width='10px' />
                        <Typography sx={{
                            fontSize: theme.palette.Content.fontSize
                        }}>{phone.remark}</Typography>
                    </Box>
                </Box>
            </Card>
        ))
    )
}


export default PhoneListView