import { Box, Typography, Tab, Tabs, Button, TextField, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import PropTypes from 'prop-types';
import ApiService from "../api_service/api_service";
import {priceSplitor} from "../core/price_splitor";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CameraIcon from '@mui/icons-material/Camera';
import MemoryIcon from '@mui/icons-material/Memory';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import SearchIcon from '@mui/icons-material/Search';
import HTMLRenderer from 'react-html-renderer'
import IPhone from '../assets/images/apple-iphone-12-mini.jpg'

import ColoredCircle from "../components/ColoredCircle";
import theme from "../utils/theme";
import CompareWith from "../components/CompareWith";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProductDetail = () => {
    var [isSearch, setIsSearch] = useState(false);
    var [name, setName] = useState('');
    var [images, setImages] = useState([]);

    const id = new URLSearchParams(document.location.search);
    // const search = document.location.search;
    // console.log(search);

    const [product, setProduct] = useState({});
    const [phones, setPhones] = useState([]);

    React.useEffect(() => {

        const getProduct = async () => {
               let response = await ApiService.productById(id);
               setProduct(response.data);
               setImages(response.data.images);
               console.log(response.data);
                let pdt = response.data;
               let phList = [];
            //    console.log(`ID is ${id}`);
            //    console.log(pdt.variations);
               for(var i = 0; i < pdt.variations.length; i++){
                    phList.push({
                        id: pdt.variations[i]._id,
                        imgPath: ApiService.basedPublicURL + pdt.variations[i].photo,
                        color: pdt.variations[i].color,
                        colorName: pdt.variations[i].name,
                        isSelected: i == 0 ? true : false
                    });
               }
               setPhones(phList);
        };
   
        getProduct();
       }, []);


    const handleSelectColor = (selectedColor) => {
        const updatedPhones = phones.map(phone => ({
            ...phone,
            isSelected: phone.color === selectedColor,
        }));

        setPhones(updatedPhones);
    }

    const selectedPhone = phones.length == 0 ? 
    {
        id: '1',
        imgPath: IPhone,
        color: '#ff0000',
        colorName: 'Red',
        isSelected: true,
    }
    : phones.find(phone => phone.isSelected);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Box sx={{
            px: { xs: '20px', sm: '100px', md: '100px' },
            pb: '70px'
            
        }}>
            <Box sx={{
                display: 'flex',
                p: '10px'
            }}>
                <Typography>All Products</Typography>
                <Box width='5px' />
                <KeyboardDoubleArrowRightIcon />
                <Box width='5px' />
                <Typography>{product.name}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                width: '100%',
                py: '50px'
            }}>
                <Box sx={{
                    width: { md: '40%' },
                    pb: { xs: '20px', sm: '20px' }
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            alt='Header Icon'
                            loading="lazy"
                            src={ selectedPhone.imgPath }
                            style={{width: '400px', height: '400px' }}
                        />
                    </Box>
                    <Typography sx={{
                        pt: "10px",
                        textAlign: 'center',
                        fontSize: theme.palette.SmallFont.fontSize
                    }}>{selectedPhone.colorName}</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: '10px'
                    }}>
                        {phones.map((phone) => (
                            <ColoredCircle
                                key={phone._id}
                                color={phone.color}
                                isSelected={phone.isSelected}
                                onClick={() => handleSelectColor(phone.color)} // Pass a callback function
                            />
                        ))}
                    </Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: theme.palette.SmallFont.fontSize
                    }}>
                    
                    </Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: theme.palette.Content.fontSize,
                        py: '10px'
                    }}>{product.brand == null ? "" : product.brand.name } {product.name}</Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: theme.palette.SubTitle.fontSize,
                        // color: theme.palette.primary.main
                    }}>{product.price == null ? 0 : priceSplitor(product.price) } Ks</Typography>
                    {
                        product.discount > 0 ? <Typography sx={{
                            textAlign: 'center',
                            fontSize: theme.palette.Content.fontSize,
                            color: "#02ab3d"//theme.palette.primary.main
                        }}> Discount { priceSplitor(product.discount)} Ks</Typography> : <Box></Box>
                    }
                </Box>
                <Box sx={{
                    width: { md: '60%' }
                }}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {images.map((step, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 200,
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={ ApiService.basedPublicURL + step }
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <Typography sx={{
                        py: '20px'
                    }}>
                    <HTMLRenderer
                    html={product.description} />
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                width: '100%'
            }}>
                
                {/* Mobile Phone => 65cb5c641e133cb8436e4dd4
                    Table => 65cc477482513024d3c7f231
                    Mac => 65cc477a82513024d3c7f236
                */}
                
                {
                    product.category != null ? 
                    (product.category._id == "65cb5c641e133cb8436e4dd4" || product.category._id == "65cc477482513024d3c7f231" || product.category._id == "65cc477a82513024d3c7f236" )
                    ? (
                        <Box sx={{
                            width: { md : '70%' }
                        }}>
                            <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="disabled tabs example">
                                <Tab label="Main Features" sx={{ fontSize: theme.palette.Content.fontSize, textTransform: 'none' }} />
                                <Tab label="Specification" sx={{ fontSize: theme.palette.Content.fontSize, textTransform: 'none' }} />
                            </Tabs>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <MainFeature props={{ product }} />
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <Specification props={{ product }}/>
                                </TabPanel>
                            </SwipeableViews>
                        </Box>
                    )
                    : (<Box></Box>)
                    : (<Box></Box>)
                }
                
                <Box sx={{
                    borderLeft: { md: '2px solid black' },
                    px: { md: '50px' },
                    mt: '50px',
                    ml: { md: '50px' },
                    width: {md: '30%'}
                }}>
                    <Typography sx={{
                        fontSize: theme.palette.Content.fontSize,
                        pb: '10px'
                    }}>Compare with</Typography>
                    <Box sx={{
                        border: '1px solid #CCCCCC',
                        borderRadius: '5px',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label='Search Model ...'
                            size="small"
                            onChange={(event) => {
                                setIsSearch(false);
                                setName(event.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0px', // Set border radius to 0px
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '0px !important', // Ensure the outline border radius is also 0px
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: '0px',
                                py: '8px'
                            }}
                            onClick={() => {
                                setIsSearch(true);
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </Box>
                    {
                        isSearch ? <CompareWith props={{name: name, firstId: product._id }}/> : <Box></Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const MainFeature = (props) => {
    const product = props.props.product;
    const specs = product.specifications;
    var cpu;
    var ram;
    var screenSize;
    var resolution;
    var battery;
    var mainCamera;
    var selfieCamera;
    specs.forEach((spec) => {

        //Mac, Mobile Phone, Tablet
        // if(spec.specificationId == "65f2d05f1f22b18c241b228a" || spec.specificationId == "65f2b74b1f22b18c241a6f06" || spec.specificationId == "65f2c8a41f22b18c241b0adc"){
        //     cpu = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b228b" || spec.specificationId == "65f2b74b1f22b18c241a6f07" || spec.specificationId == "65f2c8a41f22b18c241b0add"){
        //     ram = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b228d" || spec.specificationId == "65f2b74b1f22b18c241a6f09" || spec.specificationId == "65f2c8a41f22b18c241b0adf"){
        //     battery = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b228e" || spec.specificationId == "65f2b74b1f22b18c241a6f0a" || spec.specificationId == "65f2c8a41f22b18c241b0ae0"){
        //     mainCamera = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b228f" || spec.specificationId == "65f2b74b1f22b18c241a6f0b" || spec.specificationId == "65f2c8a41f22b18c241b0ae1"){
        //     selfieCamera = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b2292" || spec.specificationId == "65f2b74b1f22b18c241a6f0e" || spec.specificationId == "65f2c8a41f22b18c241b0ae4"){
        //     screenSize = spec.value;
        // }
        // if(spec.specificationId == "65f2d05f1f22b18c241b2293" || spec.specificationId == "65f2b74b1f22b18c241a6f0f" || spec.specificationId == "65f2c8a41f22b18c241b0ae5"){
        //     resolution = spec.value;
        // }

        if(spec.name == "Chipset"){
            cpu = spec.value;
        }
        if(spec.name == "RAM"){
            ram = spec.value;
        }
        if(spec.name == "Battery"){
            battery = spec.value;
        }
        if(spec.name == "Main Camera"){
            mainCamera = spec.value;
        }
        if(spec.name == "Selfie Camera"){
            if(spec.value != "-"){
                selfieCamera = spec.value;
            }
        }
        if(spec.name == "Screen Size"){
            screenSize = spec.value;
        }
        if(spec.name == "Display Resolution"){
            resolution = spec.value;
        }
        
    });
    return (
        <Grid container spacing={1}>
            <Grid item xs sm>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: { md: 'center' },
                        alignItems: 'center',
                        p: '8px',
                        m: '8px',
                        bgcolor: 'rgba(128, 128, 128, 0.2)',
                        height: '120px',
                        width: '250px'
                    }}
                >
                    <PhoneIphoneIcon sx={{ fontSize: '70px', color: 'grey' }} />
                    <Box>
                        <Typography sx={{ fontSize: theme.palette.Content.SmallFont }}>
                            Screen Size
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '5px', height: '5px', borderRadius: '50%', bgcolor: 'black', m: '15px' }} />
                            <Typography sx={{ fontSize: theme.palette.Content.SmallFont }}>{screenSize}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '5px', height: '5px', borderRadius: '50%', bgcolor: 'black', m: '15px' }} />
                            <Typography sx={{ fontSize: theme.palette.Content.SmallFont }}>{resolution}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs sm>
                <Box sx={{
                    display: 'flex',
                    justifyContent: { md: 'center' },
                    alignItems: 'center',
                    p: '8px',
                    m: '8px',
                    bgcolor: 'rgba(128, 128, 128, 0.2)',
                    height: '120px',
                    width: '250px'
                }}>
                    <CameraIcon sx={{ fontSize: '70px', color: 'grey' }} />
                    <Box>
                        <Typography sx={{
                            fontSize: theme.palette.Content.SmallFont
                        }}>Camera</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>{mainCamera}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>{selfieCamera}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs sm>
                <Box sx={{
                    display: 'flex',
                    justifyContent: { md: 'center' },
                    alignItems: 'center',
                    p: '8px',
                    m: '8px',
                    bgcolor: 'rgba(128, 128, 128, 0.2)',
                    height: '120px',
                    width: '250px'
                }}>
                    <MemoryIcon sx={{ fontSize: '70px', color: 'grey' }} />
                    <Box>
                        <Typography sx={{
                            fontSize: theme.palette.Content.SmallFont
                        }}>RAM | CPU</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>{ram}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>{cpu}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs sm>
                <Box sx={{
                    display: 'flex',
                    justifyContent: { md: 'center' },
                    alignItems: 'center',
                    p: '8px',
                    m: '8px',
                    bgcolor: 'rgba(128, 128, 128, 0.2)',
                    height: '120px',
                    width: '250px'
                }}>
                    <BatteryChargingFullIcon sx={{ fontSize: '70px', color: 'grey' }} />
                    <Box>
                        <Typography sx={{
                            fontSize: theme.palette.Content.SmallFont
                        }}>Battery</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>{battery}</Typography>
                        </Box>
                        {/* <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                bgcolor: 'black',
                                m: '15px'
                            }} />
                            <Typography sx={{
                                fontSize: theme.palette.Content.SmallFont
                            }}>Li-lon</Typography>
                        </Box> */}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

const Specification = (props) => {
    const product = props.props.product;
    const specs = product.specifications;
    return (
        <table style={{ paddingTop: '30px' }}>
            {
                specs.map((spec, index) => (
                    <tr class="border-bottom">
                    <td style={{ width: '200px', verticalAlign: 'top' }}>
                        <Typography sx={{
                            fontSize: theme.palette.Content.fontSize
                        }}>{spec.name}</Typography>
                    </td>
                    <td>
                        <Typography sx={{
                            fontSize: theme.palette.Content.fontSize
                        }}>{spec.value}</Typography>
                    </td>
                </tr>))
            }
            
        </table>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default ProductDetail