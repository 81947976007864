import { Box, MobileStepper, Typography } from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ApiService from "../../api_service/api_service";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AdsCarousel = () => {

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);


  const handleStepChange = (step) => {
    setActiveStep(step);
  };


  const [ imageList, setImageList ] = React.useState([]);
  const [maxSteps, setMaxSteps] = React.useState(0);

  React.useEffect(() => {

   const getImageList = async () => {
          let response = await ApiService.banner();
          setImageList(response.data);
          setMaxSteps(imageList.length);
   };
   getImageList();
  }, []);

  return (
    <Box>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imageList.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={ApiService.basedPublicURL + step.image }
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
        />
      </Box>
    </Box>
  )
}

export default AdsCarousel