import { Typography, Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../utils/theme";
import ApiService from "../../api_service/api_service";
import { Link } from "react-router-dom";
import {priceSplitor} from "../../core/price_splitor";


const NewPhones = (props) => {
    const offset = props.props.offset;
    const [ phoneList, setPhoneList ] = useState([]);

    useEffect(() => {

        const getPhoneList = async () => {
            let response = await ApiService.getNewPhones(offset);
            setPhoneList(response.data.docs);
        };

        getPhoneList();
        }, []);

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography sx={{
                    fontSize: theme.palette.Title.fontSize,
                    fontWeight: 'bold'
                }}>New Arrivals</Typography>
                <Button sx={{
                    fontSize: theme.palette.Button.fontSize
                }}
                component={Link}
                to={`/all-products?view=new` }
                >View All</Button>
            </Box>
            <Box height='50px' />
            <Grid container spacing={2}>
                {
                    phoneList.map((phone, index) => (
                        <Grid item xs key={index}>
                            <Card
                                sx={{
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                >
                                <Box sx={{
                                    textAlign: 'center',
                                    py: '20px'
                                }}
                                component={Link}
                                to={`/product-detail?id=${phone._id}`}
                                >
                                    <img
                                        alt='Header Icon'
                                        loading="lazy"
                                        src={ ApiService.basedPublicURL + phone.thumbnail}
                                        style={{ height: '150px', width: '150px' }}
                                    />
                                </Box>
                                <Box sx={{
                                    px: '20px',
                                    pb: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                }}>
                                    <Typography sx={{
                                        fontWeight: 600,
                                    }}>{phone.brand.name}</Typography>
                                    <Typography sx={{
                                        fontWeight: 600,
                                    }}
                                    >{phone.name}</Typography>
                                    <Typography sx={{
                                        pt: '10px',
                                        textAlign: 'end',
                                        // color: theme.palette.primary.main
                                    }}>{priceSplitor(phone.price)} KS</Typography>
                                    <Typography sx={{
                                        textAlign: 'end',
                                        color: theme.palette.primary.main
                                    }}>{phone.remark}</Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default NewPhones