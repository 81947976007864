import * as React from 'react';
import { Typography, Button, Hidden } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import theme from "../../utils/theme";
import ApiService from "../../api_service/api_service";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MobileBrands = () => {
    const carouselTheme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };


    const [ adsList, setAdsList ] = React.useState([]);
    const [maxSteps, setMaxSteps] = React.useState(0);
  
    React.useEffect(() => {
  
     const getAdsList = async () => {
            let response = await ApiService.ads();
            setAdsList(response.data);
            setMaxSteps(adsList.length);
     };
     getAdsList();
    }, []);

    return (
        <Box>
            <Typography sx={{
                fontSize: theme.palette.Title.fontSize,
                fontWeight: 'bold'
            }}>Mobile Phone Brands</Typography>
            {/* <Typography sx={{
                fontSize: theme.palette.Content.fontSize
            }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography> */}
            <Box height='50px' />
            <Hidden mdDown>
                <Box paddingX='100px'>
                    <AutoPlaySwipeableViews
                        axis={carouselTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {adsList.map((step, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 && (
                                    <Box sx={{ display: 'flex' }}>
                                        <img
                                            src={ApiService.basedPublicURL + step.image}
                                            alt={step.title}
                                            style={{ height: 400, overflow: 'hidden', width: '100%' }}
                                        />
                                        <Box sx={{
                                            width: '40%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            px: '20px'
                                        }}>
                                            <Typography sx={{
                                                fontSize: theme.palette.Title.fontSize,
                                                fontWeight: 'bold'
                                            }}>{step.title}</Typography>
                                            <Typography sx={{
                                                fontSize: theme.palette.Content.fontSize
                                            }}>{step.description}</Typography>
                                            <Box height='20px' />
                                            {/* <Button variant="outlined">View</Button> */}
                                        </Box>
                                        <Box width='5px' bgcolor={theme.palette.primary.main} />
                                    </Box>
                                )}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <AutoPlaySwipeableViews
                    axis={carouselTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {adsList.map((step, index) => (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 && (
                                <Box>
                                    <img
                                        src={ApiService.basedPublicURL + step.image}
                                        alt={step.title}
                                        style={{ overflow: 'hidden', width: '100%' }}
                                    />
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        px: '20px',
                                        pb: '20px'
                                    }}>
                                        <Typography sx={{
                                            fontSize: theme.palette.Title.fontSize,
                                            fontWeight: 'bold'
                                        }}>{step.title}</Typography>
                                        <Typography sx={{
                                            fontSize: theme.palette.Content.fontSize
                                        }}>{step.description}</Typography>
                                        <Box height='20px' />
                                       
                                    </Box>
                                </Box>
                            )}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </Hidden>
        </Box>
    )
}

export default MobileBrands;
